import React from 'react';
import PropTypes from 'prop-types';
import styles from '@styles/QtyController.module.css';
import { MdOutlineAddCircleOutline, MdRemoveCircleOutline } from 'react-icons/md';

function QtyController({ bookingInfo = {}, bundlePriced = false, hasFamilyPlan = false, req = {}, setReq = () => {} }) {
    const addFlatQty = () => {
        const req = {
            bundleQty: bookingInfo.bundleQty + 1,
        };
        setReq(req);
    };

    const minusFlatQty = () => {
        let qty = bookingInfo.bundleQty - 1;
        if (qty < 0) {
            qty = 0;
        }
        const req = {
            bundleQty: qty,
        };
        setReq(req);
    };

    const addAdult = () => {
        const req = {
            numAdult: bookingInfo.adult + 1,
            numChild: bookingInfo.child,
            numFamily: bookingInfo.family,
        };
        setReq(req);
    };

    const minusAdult = () => {
        let qty = bookingInfo.adult - 1;
        if (qty < 0) {
            qty = 0;
        }
        const req = {
            numAdult: qty,
            numChild: bookingInfo.child,
            numFamily: bookingInfo.family,
        };
        setReq(req);
    };

    const addChild = () => {
        const req = {
            numAdult: bookingInfo.adult,
            numChild: bookingInfo.child + 1,
            numFamily: bookingInfo.family,
        };
        setReq(req);
    };

    const minusChild = () => {
        let qty = bookingInfo.child - 1;
        if (qty < 0) {
            qty = 0;
        }
        const req = {
            numAdult: bookingInfo.adult,
            numChild: qty,
            numFamily: bookingInfo.family,
        };
        setReq(req);
    };

    const addFamily = () => {
        const req = {
            numAdult: bookingInfo.adult,
            numChild: bookingInfo.child,
            numFamily: bookingInfo.family + 1,
        };
        setReq(req);
    };

    const minusFamily = () => {
        let qty = bookingInfo.family - 1;
        if (qty < 0) {
            qty = 0;
        }
        const req = {
            numAdult: bookingInfo.adult,
            numChild: bookingInfo.child,
            numFamily: qty,
        };
        setReq(req);
    };

    return (
        <>
            {bundlePriced ? (
                <div className={styles.controller_container}>
                    <MdRemoveCircleOutline className={styles.icon} onClick={minusFlatQty} />
                    <span className={styles.number}>{bookingInfo.bundleQty}</span>
                    <MdOutlineAddCircleOutline className={styles.icon} onClick={addFlatQty} />
                </div>
            ) : (
                <>
                    <div className={styles.controller_container}>
                        <MdRemoveCircleOutline className={styles.icon} onClick={minusAdult} />
                        <span className={styles.number}>{bookingInfo.adult}</span>
                        <MdOutlineAddCircleOutline className={styles.icon} onClick={addAdult} />
                    </div>

                    <div className={styles.controller_container}>
                        <MdRemoveCircleOutline className={styles.icon} onClick={minusChild} />
                        <span className={styles.number}>{bookingInfo.child}</span>
                        <MdOutlineAddCircleOutline className={styles.icon} onClick={addChild} />
                    </div>
                    {hasFamilyPlan && (
                        <div className={styles.controller_container}>
                            <MdRemoveCircleOutline className={styles.icon} onClick={minusFamily} />
                            <span className={styles.number}>{bookingInfo.family}</span>
                            <MdOutlineAddCircleOutline className={styles.icon} onClick={addFamily} />
                        </div>
                    )}
                </>
            )}
        </>
    );
}

QtyController.propTypes = {
    bookingInfo: PropTypes.shape({
        bundleQty: PropTypes.number.isRequired,
        adult: PropTypes.number.isRequired,
        child: PropTypes.number.isRequired,
        family: PropTypes.number.isRequired,
    }).isRequired,
    bundlePriced: PropTypes.bool,
    hasFamilyPlan: PropTypes.bool,
    req: PropTypes.object,
    setReq: PropTypes.func.isRequired,
};

export default QtyController;
